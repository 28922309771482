import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Logo from "../components/logo";
import { StaticImage } from "gatsby-plugin-image";

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 | Your page doesn't exist</title>
        <meta name="author" content="readmerisen" />
        <meta name="twitter:title" content="Teko Merah Design Studio"></meta>
      </Helmet>
      <main>
        <div className="not-found">
          <div className="bumper-content">
            <Logo size={"8rem"} />
            <h1>404: Yikes! What you are looking for doesn't exist!</h1>
            <Link to="/">Back to homepage</Link>
          </div>
          <div className="bg-image">
            <StaticImage
              src="../images/projects/tmbdmall.jpg"
              alt="bumper background"
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
